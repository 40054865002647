<template>
    <div class="componentUpload">
        <MemberComponent  :changeList="changeList"></MemberComponent>
    </div>
</template>
<script>
    import MemberComponent from '../../components/MemberComponent.vue';
    export default {
        name:'memberUpload',
        components: { MemberComponent },
        data () {
            return {
                changeList:{
                    closeIcon:false,
                    ruleForm:{},
                },
            }
        },
        methods:{
            getList(){

            }
        },
        mounted() {
            // this.$store.mutations.ClearKeep(this.$store.state,'memberUpload')
        },
    }
</script>
<style scoped>
    .demo-input-label {
        display: inline-block;
        width: 130px;
    }
</style>